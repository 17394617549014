* {
  margin: 0;
  padding: 0;
  font-family: "IBM Plex Sans" !important;
}

body,
html {
  height: 100vh;
  background: #e5e5e5;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.root {
  height: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #e5e5e5;
  font-family: "IBM Plex Sans";
}

.input {
  max-width: 23.3vw;
  height: 3.12vw;
  padding-left: 1.04vw;
  border: 1px solid #bdbdbd;
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 150%;
  outline: none;
  font-family: "IBM Plex Sans";
}

.input_error {
  max-width: 23.3vw;
  height: 3.12vw;
  padding-left: 1.04vw;
  border: 1px solid #eb5757;
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 150%;
}

.input:focus-visible {
  border: 1px solid #8ab528;
}

.form-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5.2vw 0 8.8vw;
  padding: 0 0.83vw;
  max-width: 32.5vw;
}

.form-wrapper-login {
  margin-top: 0;
}

.form-container {
  box-sizing: border-box;
  height: auto;
  width: 30.8vw;
  display: flex;
  flex-direction: column;
  gap: 1.3vw;
  padding: 2.08vw 3.75vw;
  background-color: #ffffff;
}

.form-container-register {
  margin-bottom: 5.2vw;
}

.container-login {
  margin-top: 0;
}

.form-title {
  font-size: 1.6vw;
  font-weight: 600;
  width: 27.7vw;
  margin: 2.08vw 4.47vw 0 0;
  margin: 0;
  padding: 0;
}

.form-subtitle {
  font-size: 0.833vw;
  font-weight: 400;
  width: 100%;
  margin: 1.7vw 4.47vw 0 0;
  letter-spacing: 0.01em;
  line-height: 150%;
  margin: 0;
  padding: 0;
}

.field-container {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 0.26vw;
}

.field-title {
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 1.25vw;
  margin: 0;
  padding: 0;
}

.header {
  width: 100%;
  box-sizing: border-box;
  padding-top: 3.3vw;
  padding-left: 18.3vw;
}

.header > a {
  margin: 0;
  position: relative;
  box-sizing: border-box;
}

.header-image {
  width: 5.43vw;
  height: 3.33vw;
}

.header__description > h1 {
  margin-top: -4.1vw;
  font-weight: 600;
  font-size: 1.66vw;
}

.header__description > h3 {
  font-weight: 400;
  font-size: 0.833vw;
  margin: 1.5vw 0;
}

.form-error {
  font-size: 1.3vw;
  max-width: 30.8vw;
  min-height: 1.56vw;
}

.form-button {
  width: 100%;
  height: 3.1vw;
  color: white;
  border: 1px solid white;
  background: #8ab528;
  cursor: pointer;
  transition: 0.2s;
  font-size: 1.04vw;
  font-weight: 700;
  font-family: "IBM Plex Sans";
}

.button-login {
  margin-left: 0;
}

.form-button:hover {
  background: #181818;
  color: #fff;
}

.form__link {
  color: #8ab528;
  width: 20.83vw;
  margin: -0.5vw 11.15vw 0 0;
  font-size: 0.729vw;
  text-decoration: unset;
}

.login {
  margin-bottom: 0;
}

.about {
  font-style: normal;
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 150%;
  align-self: flex-start;
  margin-bottom: 2.6vw;
}

.accept {
  width: 20.83vw;
  margin: -0.5vw 11.15vw 0 0;
  font-size: 0.729vw;
  text-decoration: unset;
}

.password-image {
  position: absolute;
  bottom: calc(15 / 1920 * 100vw);
  right: calc(20 / 1920 * 100vw);
}

.password-image:hover,
.password-image__login:hover,
.password-image-setting:hover  {
  filter: brightness(0) saturate(100%) invert(64%) sepia(17%) saturate(1697%)
  hue-rotate(37deg) brightness(99%) contrast(83%);
}

.password-image-error {
  position: absolute;
  bottom: calc(15 / 1920 * 100vw);
  right: calc(20 / 1920 * 100vw);
}

.password-image-error-register {
  bottom: 46%;
}
.password2-image-error-register {
  bottom: 37%;
}

.password-image-error-modal {
  bottom: 42%;
}

.password3-image-error-modal {
  bottom: 33%;
}

.password-image__login {
  position: absolute;
  bottom: calc(15 / 1920 * 100vw);
  right: calc(20 / 1920 * 100vw);
}

.password-image-error__login {
  position: absolute;
  bottom: 47%;
  left: 90%;
}

.valid-error {
  color: #eb5757;
  font-weight: 400;
  font-size: 0.729vw;
  line-height: 150%;
  margin-top: 0.26vw;
  margin-right: 2.6vw;
}

.footer {
  align-self: flex-start;
}

.footer p {
  font-weight: 400;
  font-size: 0.729vw;
  line-height: 150%;
}

.footer a {
  font-weight: 600;
  font-size: 0.729vw;
  line-height: 150%;
  color: #8ab528;
  text-decoration: none;
}

.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  padding-bottom: 2.08vw;
  overflow-y: hidden;
  position: relative;
  max-width: 41.6vw;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 0.208vw;
  border: 1px solid #999;
}

.box > h1 {
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 130%;
  margin: 3.8vw 2.7vw 0 2.7vw;
}

.box > ol {
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin: 2.08vw 3vw 0 3.7vw;
}

.box__footer {
  font-weight: 600;
  font-size: 0.833vw;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin: 2.08vw 2.7vw 40px 2.7vw;
}

.close-icon {
  content: "X";
  cursor: pointer;
  position: relative;
  top: 3vw;
  width: 1.3vw;
  height: 1.3vw;
  color: #bdbdbd;
  line-height: 1.04vw;
  text-align: center;
  font-size: 1.025vw;
  padding-left: 38.3vw;
}

.delete-icon:hover *{
  fill: #8AB528;
}

.forget-password-links {
  display: flex;
  align-items: baseline;
  gap: 0.52vw;
  height: 1.1vw;
  margin-bottom: 0px;
}

.forget-password-links > a {
  width: auto;
  margin-right: 0;
}

.finish-register {
  margin-top: 17.7vw;
}

.valid-email {
  color: #8ab528;
  text-align: center;
  font-size: 0.93vw;
}

.user-page {
  display: flex;
  width: 100%;
  height: 100%;
}

.side-bar {
  position: fixed;
  width: 10.41vw;
  height: 100%;
  background-color: #181818;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 560;
}

.loader-container {
  display: flex;
  justify-content:center;
  align-items: center;
  margin-top: 10vw;
}

.content {
  width: calc(100% - 10.41vw);
  margin-left: auto;
  overflow-y: auto;
  padding-bottom: 3.9vw;
}

.side-bar__icon {
  padding: 2.5vw 1.7vw 4.58vw 1.7vw;
}

.document-button {
  width: 100%;
  height: 10.41vw;
  display: flex;
  flex-direction: column;
  gap: 0.78vw;
  align-items: center;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 130%;
  color: #fff;
}

.document-button:not(.active):hover {
  cursor: pointer;
  background-color: #333333;
}

.active {
  background-color: #8ab528;
  font-weight: 700;
}

.document-icon {
  margin-top: 3.07vw;
}

.counterparty-button {
  width: 100%;
  height: 10.41vw;
  display: flex;
  flex-direction: column;
  gap: 1.19vw;
  align-items: center;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 130%;
  color: #fff;
}

.counterparty-button:not(.active):hover {
  cursor: pointer;
  background-color: #333333;
}

.counterparty-icon {
  margin-top: 3.17vw;
}

.help-icon {
  margin-top: 9.47vw;
}

.help-icon:hover {
  filter: brightness(0) saturate(100%) invert(64%) sepia(17%) saturate(1697%)
    hue-rotate(37deg) brightness(99%) contrast(83%);
  margin-top: 9.47vw;
}

.description-header {
  margin: 3.02vw 4.16vw 0 0;
  text-align: right;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 130%;
  color: #181818;
}

.description-header.main:hover {
  filter: brightness(0) saturate(100%) invert(64%) sepia(17%) saturate(1697%)
    hue-rotate(37deg) brightness(99%) contrast(83%);
}

.description-header .main {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  padding-left: 2vw;
}

.main__header-content {
  display: flex;
}

.main__header-content:hover {
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(64%) sepia(17%) saturate(1697%)
    hue-rotate(37deg) brightness(99%) contrast(83%);
}

.description-header_header-icon {
  margin-right: 1.04vw;
}

.document-header {
  font-weight: 700;
  font-size: 2.08vw;
  color: #181818;
  padding-left: 2.08vw;
}

.new-document > h1 {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 130%;
  color: #181818;
  margin-top: 2vw;
}

.new-document {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 150%;
  color: #181818;
}

.new-document > h3 {
  font-weight: 600;
  font-size: 0.93vw;
  line-height: 130%;
  margin-top: 2.08vw;
  margin-bottom: 1.3vw;
}

.new-document > span {
  margin-left: 1.04vw;
}

.new-document__checkbox {
  margin-top: 2.08vw;
  display: flex;
  gap: 1.04vw;
}

.new-document__button-block {
  margin-top: 2.08vw;
  display: flex;
  gap: 2.34vw;
}

.button-signature {
  width: 23%;
  border: none;
}

.button-signature__empty {
  width: 23%;
  border: none;
  background-color: #BDBDBD;
  color: #fff;
  border: 1px solid #BDBDBD;
}

.button-signature__empty:hover{
  width: 23%;
  border: none;
  background-color: #BDBDBD;
  color: #fff;
  border: 1px solid #BDBDBD;
}

.button-cancel {
  width: 23%;
  background-color: #fff;
  color: #bdbdbd;
  font-weight: 600;
}

.button-cancel__secondary {
  width: 23%;
  border: 1px solid #181818;
  background-color: #fff;
  color: #181818;
}

.button-cancel__new-document {
  border: 1px solid #bdbdbd;
}

.button-cancel:hover  {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.button-cancel__new-document:hover{
  width: 23%;
  background-color: #fff;
  color: #bdbdbd;
  font-weight: 600;
  border: 1px solid #bdbdbd;
}

.no-document {
  width: calc(1600 / 1920 * 100vw);
  height: 36vw;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "IBM Plex Sans";
  letter-spacing: 0.01em;
  margin-top: 2.08vw;
}

.no-document > h1 {
  margin-top: 2.6vw;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 130%;
  color: #bdbdbd;
}

.no-document > p {
  margin-top: 1.04vw;
  font-weight: 400;
  font-size: 0.93vw;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #181818;
  text-align: center;
}

.no-document > button {
  width: 18.2vw;
  margin-top: 2.08vw;
}

.in-coming {
  padding-top: 5.5vw;
}

.out-coming {
  padding-top: 4.8vw;
}

.no-wait {
  padding-top: 5.1vw;
}

.no-invite {
  padding-top: 4.7vw;
}

/* ЭЦП */

.signature-close {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.07vw;
}

.signature-choose {
  color: #181818;
  font-size: 1.29vw;
  font-weight: 500;
  margin-bottom: 2.15vw;
}

.signature-center {
  max-height: 50vh;
  /* scrollbar-gutter: stable; */
  scrollbar-width: thin;
  overflow-y: auto;
}

.signature-center::-webkit-scrollbar {
  width: 1.07vw;
}

.signature-center::-webkit-scrollbar-track {
  border-radius: 0.53vw;
}

.signature-center::-webkit-scrollbar-thumb {
  border-left: 4px solid;
  border-right: 4px solid;
  border-color: transparent;
  background-color: #8ab528;
  border-radius: 100px;
  background-clip: content-box;
}

.signature-cert-selected {
  border: 1px solid #8ab528;
  color: #181818;
  height: 4.95vw;
  font-size: 0.86vw;
  box-sizing: border-box;
  margin-bottom: 1.07vw;
  padding: 0.53vw;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.signature-cert {
  border: 1px solid #bdbdbd;
  color: #181818;
  height: 4.95vw;
  font-size: 0.86vw;
  box-sizing: border-box;
  margin-bottom: 1.07vw;
  padding: 0.53vw;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.signature-cert:last-of-type,
.signature-cert-selected:last-of-type {
  margin-bottom: 0;
}

.signature-cert__name-selected {
  color: #8ab528;
  font-weight: 600;
  font-size: 0.97vw;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.signature-cert__left-block {
  display: flex;
}

.signature-cert__checkbox-icon {
  margin-right: 0.53vw;
}

.signature--gray {
  color: #949daf;
}

.signature-cert__name {
  font-weight: 600;
  font-size: 0.97vw;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.signature-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2.15vw 0 0;
}

.signature-sign {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.07vw;
  width: 24.14vw;
  height: 3.23vw;
  background-color: #8ab528;
  color: #fff;
  margin: 2.15vw 0;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
}

.signature-sign__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.07vw;
  width: 24.14vw;
  height: 3.23vw;
  background-color: #BDBDBD;
  color: #fff;
  border: 1px solid #BDBDBD;
  margin: 2.15vw 0;
  box-sizing: border-box;
  cursor: pointer;
}

.signature-sign:hover,
.signature-sign:active {
  background-color: #181818;
}

.signature-cancel {
  display: flex;
  color: #181818;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.07vw;
  width: 24.14vw;
  height: 3.23vw;
  border: 1px solid #000;
  box-sizing: border-box;
  cursor: pointer;
}

.signature-button--expanded {
  width: 26.19vw;
  height: 3.23vw;
  margin: 0;
}

.signature-cancel:hover {
  background-color: #181818;
  color: #fff;
}

.signature-success-close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  height: 2.64vw;
}

.signature-success-text {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1.29vw;
}

.signature-succes-text__lower {
  margin: 1.29vw 0 2.15vw;
}

.signature-success-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.07vw;
  width: 24.14vw;
  height: 3.23vw;
  background-color: #8ab528;
  color: #fff;
  box-sizing: border-box;
  cursor: pointer;
}

.signature-error-text {
  font-size: 1.29vw;
  font-weight: 500;
  margin-bottom: 1.34vw;
}

.signature-error-guide {
  font-size: 1.07vw;
  margin-bottom: 2.15vw;
}

.signature-chosen-text {
  height: 3.34vw;
  font-size: 1.29vw;
  font-weight: 500;
  padding-bottom: 1.07vw;
  color: #181818;
}

.signature-waiting-text {
  font-size: 1.5vw;
  font-weight: 500;
}

.new-counterparty {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #181818;
}

.new-counterparty > h1 {
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 130%;
  margin: 2vw 0;
}

.new-counterparty > p {
  max-width: 55vw;
  margin-bottom: 2vw;
}

.new-couterparty-lable {
  margin-top: 2vw;
}

.new-counterparty-dropdown {
  width: calc(1600 / 1920 * 100vw);
  height: 3.1vw;
  padding-left: 14px;
  font-weight: 400;
  font-size: 0.87vw;
  border: 1px solid #bdbdbd;
  background-color: #fff;
}

.new-counterparty-dropdown:focus-visible {
  outline-color: #8ab528;
  border: 1px solid #8ab528;
}

.text-area {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 0.87vw;
  width: calc(1600 / 1920 * 100vw);
  height: 6.2vw;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #bdbdbd;
  outline: none;
  margin-bottom: 2vw;
  resize: none;
}

.text-area:focus-visible {
  border: 1px solid #8ab528;
}

.invite-header {
  margin-top: 2vw;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #181818;
  margin-bottom: 2vw;
}

.invite-counter-party {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #181818;
  width: 55vw;
  margin: 2vw 0;
}

.invite-counter-party-button {
  width: calc(1600 / 1920 * 100vw);
  min-height: 6.82vw;
  background-color: #fff;
  box-sizing: border-box;
  border-bottom: 1px solid #bdbdbd;
  padding: 1.5vw 2vw;
  display: flex;
}
.invite-counter-party-button > p {
  font-family: "IBM Plex Sans";
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #181818;
}

.invite-counter-party-button__description {
  width: 30.3vw;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: 1.04vw;
}

.name-company {
  font-weight: 600;
  font-size: 0.93vw;
}
.bin-company {
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  font-weight: 400;
  font-size: 0.833vw;
}

.date-invite {
  width: 4.9vw;
  margin-left: 2.08vw;
  margin-right: 4.06vw;
  font-size: 0.833vw;
}

.buttons-invite {
  width: 100%;
  margin: 0;
  align-items: start;
  gap: 1.66vw;
}

.button-accepted {
  width: 18.2vw;
}

.button-refuse {
  width: 18.2vw;
  border: 1px solid #181818;
  background-color: #fff;
  color: #181818;
}

/* REFACTOR!!! */

.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0 !important;
}

.setting > h1 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #181818;
  margin-top: 1.66vw;
}

.setting-indicator {
  margin-top: 0.88vw;
  width: 6.77vw;
  height: 0.52vw;
  background: #8ab528;
  position: relative;
  z-index: 1;
  top: 0.05vw;
}

.setting-line {
  width: calc(1600 / 1920 * 100vw);
  height: 0.104vw;
  background: #bdbdbd;
  margin-bottom: 2vw;
  position: relative;
  top: -1px;
}

.setting-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(1600 / 1920 * 100vw);
  height: 4.32vw;
  background-color: #fff;
  border-bottom: 1px solid #bdbdbd;
  box-sizing: border-box;
  padding: 0.6vw 2vw;
}

.setting-input-company-name {
  height: 5.7vw;
  align-items: center;
  border-bottom: 2px solid #8ab528;
  word-break: break-all;
}

.setting-input-company-name__value {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #181818;
}

.setting-input__title {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 0.93vw;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #949daf;
  flex-basis: 29.6vw;
}

.setting-input__value {
  flex-basis: 26.1vw;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.93vw;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #181818;
  flex: 1;
  margin-left: 4vw;
}

.setting-input-company-id {
  padding-top: 0.6vw;
}

.setting-input-button {
  cursor: pointer;
}

.setting-input-button:hover {
  filter: brightness(0) saturate(100%) invert(64%) sepia(17%) saturate(1697%)
    hue-rotate(37deg) brightness(99%) contrast(83%);
}

.popup-box-settings {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 550;
}

.box-settings {
  overflow-y: hidden;
  position: relative;
  max-width: 30.8vw;
  margin: 0 auto;
  height: auto;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 0.208vw;
  border: 1px solid #999;
  padding: 2.6vw 3.7vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}

.box-settings-error {
  margin-top: 4%;
}

.box-settings > h1 {
  font-family: "IBM Plex Sans";
  font-weight: 600;
  font-size: 1.6vw;
  line-height: 130%;
  color: #181818;
}

.close-icon-settings {
  content: "X";
  cursor: pointer;
  position: relative;
  color: #bdbdbd;
  line-height: 1.04vw;
  text-align: center;
  font-size: 1.025vw;
  padding-left: 22.3vw;
}

.box-settings-description {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #181818;
  margin: 2vw 0 1vw 0;
}

.box-settings-input-title {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #181818;
}

.box-settings-input-title.big {
  font-size: calc(24/1920*100vw);
  line-height: calc(31.2/1920*100vw);
  font-weight: 500;
  display: flex;
  flex-direction: column;
  row-gap: calc(35/1920*100vw);
  margin-top: calc(20/1920*100vw);
}

.box-settings-buttons {
  margin-top: 2.08vw;
  display: flex;
  flex-direction: column;
  gap: 2.08vw;
}

.button-cancel-signature {
  background-color: #fff;
  color: #181818;
  border: 1px solid #181818;
}

.box-settings__password-form {
  display: flex;
  flex-direction: column;
  gap: 1.04vw;
  margin-top: 2.08vw;
}

.password-image-setting {
  bottom: 10%;
}

.welcome-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-block__title {
  color: #8ab528;
  font-size: 1.72vw;
  font-weight: 600;
  text-align: center;
  margin: 2.69vw 0;
}

.welcome-block__text {
  color: #181818;
  font-size: 0.97vw;
  text-align: center;
  margin-bottom: 2.69vw;
}

.welcome-block__cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.07vw;
  width: 24.14vw;
  height: 3.23vw;
  background-color: #8ab528;
  color: #fff;
  box-sizing: border-box;
  cursor: pointer;
}

.welcome-block__cancel:hover,
.welcome-block__cancel:active {
  background-color: #181818;
}

.MuiTablePagination-menuItem.Mui-selected {
  background-color: #fff !important;
}

.MuiPaper-root {
  box-shadow: none !important;
}

.description-header__user-menu {
  position: fixed;
  top: 0;
  right: calc(-40 / 1920 * 100vw * 2);
  background-color: white;
  width: calc(400 / 1920 * 100vw);
  height: 100vh;
  z-index: 510;
  box-sizing: border-box;
  padding: calc(40 / 1920 * 100vw);
  display: flex;
  flex-direction: column;
  width: 0;
  transition: all 0.3s ease-in-out;
}

.dark-background {
  opacity: 0;
  z-index: -200;
  transition: all 0.3s ease-in-out;
}

.dark-background.show {
  opacity: 1;
  z-index: 505;
}

.description-header.show .user-menu {
  right: 0;
  width: calc(400 / 1920 * 100vw);
}

.description-header__user-menu .MuiTouchRipple-root {
  background-color: transparent;
  text-align: left;
}

.user-menu__close-button {
  align-self: flex-start;
}

.user-menu__close-button:hover {
  filter: brightness(0) saturate(100%) invert(64%) sepia(17%) saturate(1697%)
    hue-rotate(37deg) brightness(99%) contrast(83%);
}

.user-menu__name {
  font-size: calc(32 / 1920 * 100vw);
  font-weight: 600;
  align-self: flex-start;
  margin-bottom: calc(21 / 1920 * 100vw);
  max-width: calc(319 / 1920 * 100vw);
  word-wrap: break-word;
  text-align: left;
}

.user-menu__user-id {
  margin-bottom: calc(70 / 1920 * 100vw);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.user-id__label {
  color: #949daf;
  font-weight: 400;
  text-align: left;
  font-size: calc(12 / 1920 * 100vw);
  line-height: calc(18 / 1920 * 100vw);
  row-gap: calc(5 / 1920 * 100vw);
}

.user-id__content {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
}

.user-id__content p {
  display: block;
  width: calc(250 / 1920 * 100vw);
  height: min-content;
  font-weight: 400;
  font-size: calc(12 / 1920 * 100vw);
  line-height: calc(18 / 1920 * 100vw);
}

.user-id__copy-button:hover {
  filter: brightness(0) saturate(100%) invert(64%) sepia(17%) saturate(1697%)
    hue-rotate(37deg) brightness(99%) contrast(83%);
}

.user-menu__content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.user-menu .link {
  background: none;
  border: none;
  cursor: pointer;
  height: calc(90 / 1920 * 100vw);
  align-self: flex-start;
  display: flex;
  column-gap: 20px;
  align-items: center;
  width: 100%;
}

.user-menu .link:hover {
  color: #8ab528;
}

.user-menu .link:hover svg {
  filter: brightness(0) saturate(100%) invert(64%) sepia(17%) saturate(1697%)
    hue-rotate(37deg) brightness(99%) contrast(83%);
}

.user-menu .link span {
  font-weight: 500;
  font-size: calc(24 / 1920 * 100vw);
}

.user-menu__setting {
  position: relative;
}

.user-menu__setting::after {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #000;
  left: 0;
  bottom: 0;
  cursor: auto;
}

.row__signature-status {
  display: flex;
  align-items: center;
}

.workflow {
  display: flex;
  align-items: baseline;
}

.workflow__id {
  color: #bdbdbd;
  font-size: calc(16 / 1920 * 100vw);
  font-weight: 400;
  white-space: pre;
}

.workflow__value {
  font-size: calc(14 / 1920 * 100vw);
  color: #181818;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 150%;
  width: calc(292 / 1920 * 100vw);
}

.MuiAutocomplete-listbox > li:hover {
  background-color: #8ab528 !important;
  color: #fff;
  height: 3.1vw;
}

.MuiAutocomplete-listbox > li:active {
  background-color: #8ab528 !important;
  color: #fff;
  height: 3.1vw;
}

.MuiAutocomplete-listbox > li {
  background-color: white !important;
  height: 3.1vw;
}

.MuiAutocomplete-listbox {
  padding: 0 !important;
}

.MuiAutocomplete-paper {
  border-radius: 0 !important;
  box-shadow: 0px 15px 15px -15px rgba(0, 0, 0, 0.2) !important; /* Параметры тени */
}

.MuiToolbar-root {
  width: 100% !important;
}

.MuiAutocomplete-root {
  background-color: white !important;
}

.MuiAutocomplete-root:focus-within {
  background-color: #bdbdbd !important;
}

.MuiTabs-flexContainer {
  align-items: center;
}

.MuiOutlinedInput-input {
  font-size: 0.83vw !important;
  line-height: calc(24 / 1920 * 100vw) !important;
}

.MuiTablePagination-selectLabel {
  color: #949daf !important;
  font-size: calc(14 / 1920 * 100vw) !important;
}

.MuiTablePagination-menuItem,
.MuiTablePagination-displayedRows {
  font-size: calc(14 / 1920 * 100vw) !important;
  color: #181818 !important;

}

.MuiTablePagination-root.MuiTablePagination-root{
  overflow: hidden;
  height: 3.1vw;
  align-items: center;
}

.Mui-disabled {
  background-color: #fff !important;
  color: #949DAF !important;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #fff !important;
}

.alert {
  padding-left:0.2vw;
  top: 2px;
  position: relative;
}

.date-out {
  display: flex;

}

.line-shadow{
  width: 1000%;
  height: 1px;
  margin-left: -50px;
  background: #F0F0F0;

}